<!--
 * @Author: your name
 * @Date: 2020-10-13 15:20:46
 * @LastEditTime: 2020-10-16 15:41:02
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /yue_quanzhan_h5/src/views/homePopup/newPeopleList.vue
-->
<template>
  <div>
    <img :src="activityInfo.backGround.url" alt="" style="width:100%">
    <ul class="goodsul">
      <li v-for="(item,index) in list[0].goodsInfo" :key="index" @click="handleClick(item)">
        <div class="goodsleft">
          <img :src="item.goodCover" class="goodsimg">
        </div>
        <div class="goodsright">
          <div class="goods_name"><span>优</span> {{ item.goodName }}</div>
          <div class="goodsRightImg">
            <p>立返¥{{ item.recurrencePrice }}</p>
          </div>
          <p class="prices">到手价<span>￥</span> <span>{{ item.goodVipPrice }}</span></p>
          <div class="shoppBtn">
            <p class="yjPrice">原价￥{{ item.goodPrice }}</p>
            <button>去购买</button>
          </div>
        </div>
      </li>
    </ul>
    <div class="botTxt">
      <img :src="list[0].teamBanner" alt="">
    </div>
  </div>
</template>
<script>
import { getActivityList, getActivityCategoryInfo } from '@/services/activity'
import { Toast } from 'vant'
export default {
  data() {
    return {
      activityInfo: {},
      list: []
    }
  },
  created() {
    this.getActivityList()
    this.getActivityCategoryInfo()
  },
  beforeCreate() {
    // 修改背景色
    document.querySelector('body').setAttribute('style', 'background-color:#F4B58E')
  },
  beforeDestroy() {
    // 销毁背景色
    document.querySelector('body').removeAttribute('style')
  },
  methods: {
    // 获取信息
    getActivityList() {
      this.id = this.$route.query.id
      const opt = {
        id: this.$route.query.id,
        // categoryId: 0, 不知道是什么
        page: 1,
        pageSize: 10
      }
      getActivityList(opt).then(res => {
        if (Number(res.code) === 200) {
          this.list = res.data
        }
      })
    },
    getActivityCategoryInfo() {
      const params = {
        id: this.$route.query.id
      }
      getActivityCategoryInfo(params).then((res) => {
        if (Number(res.code) === 200) {
          this.activityInfo = res.data.activityInfo
        } else {
          Toast(res.msg)
        }
      })
    },
    handleClick(item) {
      this.$router.push({
        path: '/goodsDetailZY', query: { skuid: item.skuId, activityId: item.activityId }
      })
    }

  }
}
</script>
<style scoped>
.goodsul li {
  background: #ffffff;
  border-radius: 10px;
  padding: 10px 10px 14px 10px;
  width: 350px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto 10px auto;
}

.goodsleft {
  width: 108px;
  height: 108px;
}

.goodsimg {
  width: 100%;
}

.goodsright {
  width: 198px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: space-between;
}

.goods_name {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  font-size: 15px;
  font-family: PingFang SC Regular, PingFang SC Regular-Regular;
  font-weight: 400;
  text-align: left;
  color: #333333;
  line-height: 20px;
}

.goods_name span {
  width: 16px;
  height: 16px;
  line-height: 16px;
  background: #e33f44;
  border-radius: 2px;
  font-size: 12px;
  font-family: PingFang SC Regular, PingFang SC Regular-Regular;
  font-weight: 400;
  text-align: left;
  color: #fefefe;
}

.goodsRightImg {
  width: 60px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  background: url("https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-10-13/19/yuelvhuiKGmAyLbvMV1602588085.jpg") no-repeat;
  background-size: 100% 100%;
  margin: 6px 0 14px 0;
}

.goodsRightImg p {
  font-size: 12px;
  color: #E33F44;
}

.prices {
  margin-bottom: 5px
}

.prices, .prices span:first-child {
  font-size: 12px;
  font-family: PingFang SC Regular, PingFang SC Regular-Regular;
  font-weight: 400;
  text-align: left;
  color: #333333;
}

.prices span:first-child {
  color: #E33F44;
}

.prices span:nth-child(2) {
  font-size: 16px;
  font-family: PingFang SC Medium, PingFang SC Medium-Medium;
  font-weight: 500;
  color: #e33f44;
}

.yjPrice {
  font-size: 12px;
  font-family: PingFang SC Regular, PingFang SC Regular-Regular;
  font-weight: 400;
  text-decoration: line-through;
  text-align: left;
  color: #999999;
}

.shoppBtn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.shoppBtn button {
  width: 74px;
  height: 28px;
  line-height: 28px;
  background: #e33f44;
  border-radius: 5px;
  font-size: 12px;
  font-family: PingFang SC Regular, PingFang SC Regular-Regular;
  font-weight: 400;
  text-align: center;
  color: #fefefe;
}

.botTxt {
  width: 350px;
  margin: 16px auto 46px auto;
}
</style>
